<template>
  <div>
    <TheHomepageHeader />
    <Section1 />
    <Section2 />
    <Section3 />
    <TheHomepageBottom />
    <!-- <div v-for="text in text" :key="text.Key">
        <p>{{ text }}</p>
      </div> -->
  </div>
</template>

<script>
export default {
  components: {
    TheHomepageHeader: () => import("@/components/home/TheHomepageHeader3.vue"),
    Section1: () => import("@/components/home/TheHomePageSection1.vue"),
    Section2: () => import("@/components/home/TheHomePageSection2.vue"),
    Section3: () => import("@/components/home/TheHomePageSection3.vue"),
    TheHomepageBottom: () => import("@/components/home/TheHomepageBottom.vue"),
  },
};
</script>
